import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
import {
  EventWrapper,
  ImageWrapper,
  Image,
  Info,
  InfoItem,
  Title,
  Year,
  Location,
  SocialIcon,
  EventDetails,
} from './styles';

const Event = ({
  isModalOpen,
  eventKey,
  image,
  year,
  video,
  setModalOpen,
  setVideoSettings,
}) => {
  // translation features
  const { t } = useTranslation();

  const handleVideo = (e) => {
    e.preventDefault();

    if (video === null) {
      return;
    }

    setVideoSettings({ channel: video.channel, id: video.id });
    setModalOpen(true);
  };

  return (
    <EventWrapper>
      <ImageWrapper isModalOpen={isModalOpen}>
        <Image
          video={video}
          onClick={(e) => handleVideo(e)}
          src={`${process.env.PUBLIC_URL}/assets/images/${year}/${image}`}
          alt={`Foto: ${t(`portfolio.${year}.events.${eventKey}.name`)}`}
        />
      </ImageWrapper>
      <EventDetails>
        <Year>{year}</Year>
        <Info isModalOpen={isModalOpen}>
          <div>
            <InfoItem>
              {t(`portfolio.${year}.events.${eventKey}.date`)}
            </InfoItem>
            <InfoItem>
              {t(`portfolio.${year}.events.${eventKey}.role`)}
            </InfoItem>
          </div>
        </Info>
        <Title>{t(`portfolio.${year}.events.${eventKey}.name`)}</Title>
        <Location location={t(`portfolio.${year}.events.${eventKey}.location`)}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icons/map-pin.svg`}
            alt='Ícone - mapa'
          />
          {t(`portfolio.${year}.events.${eventKey}.location`)}
        </Location>
        {!!video && (
          <SocialIcon
            network={video.channel}
            bgColor='#000'
            fgColor='#ffffff'
            onClick={(e) => handleVideo(e)}
          />
        )}
      </EventDetails>
    </EventWrapper>
  );
};

Event.defaultProps = {
  video: null,
};

Event.propTypes = {
  isModalOpen: PropTypes.bool,
  eventKey: PropTypes.string,
  image: PropTypes.string,
  year: PropTypes.string,
  video: PropTypes.shape({
    channel: PropTypes.string,
    id: PropTypes.string,
  }),
  setModalOpen: PropTypes.func,
  setVideoSettings: PropTypes.func,
};

export default Event;
