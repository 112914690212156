import React from 'react';
import { Trans } from 'react-i18next';

// styles
import { IntroWrapper, LogoWrapper, Text } from './styles';

// logo
import Logo from '../Logo/Logo';

const Title = () => (
  <IntroWrapper data-testid='section-intro'>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <Text>
      <Trans i18nKey='intro.text' />
    </Text>
  </IntroWrapper>
);

export default Title;
