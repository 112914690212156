import styled from 'styled-components';
import { SocialIcon as SocialIconComponent } from 'react-social-icons';
import tokens from '../../../../styles/tokens';

// icons

export const EventWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow: hidden;

  @media ${tokens.breakpoints.xl} {
    padding: 0 64px 0 180px;
  }

  &:before {
    display: none;
    position: absolute;
    z-index: 5;
    left: 128px;
    top: 0;
    bottom: 0;
    width: 2px;
    content: '';
    background: #ffffff;

    @media ${tokens.breakpoints.xl} {
      display: block;
    }
  }
`;

export const EventDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  padding-bottom: 32px;

  @media ${tokens.breakpoints.xl} {
    padding-bottom: 64px;
    left: 180px;
    right: 180px;
  }
`;

export const ImageWrapper = styled.figure`
  width: 100%;
  height: 50%;
  margin: 10% 0 32px;
  padding: 0;
  transition: filter 0.2s ease;

  ${({ isModalOpen }) => isModalOpen && 'filter: blur(4px);'}

  @media ${tokens.breakpoints.xl} {
    text-align: right;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: auto;
  position: relative;
  z-index: 5;
  ${({ video }) => !!video && 'cursor: pointer;'}
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  transition: filter 0.2s ease;

  ${({ isModalOpen }) => isModalOpen && 'filter: blur(4px);'}
`;

export const Title = styled.h1`
  ${tokens.font['eventTitle-sm']};

  position: relative;
  z-index: 2;
  margin: 8px 0;

  @media ${tokens.breakpoints.xl} {
    ${tokens.font.eventTitle};
  }
`;

export const InfoItem = styled.p`
  ${tokens.font.subtitle}
  margin-left: 0;

  font-family: ${tokens.font.family.bebas};
`;

export const Location = styled.div`
  ${tokens.font.subtitle}
  display: inline-block;
  vertical-align: middle;
  font-family: ${tokens.font.family.bebas};
  margin: 0 32px 0 0;
  display: ${({ location }) => (location ? 'inline-block' : 'none')};

  img {
    margin-right: 16px;
    vertical-align: middle;
  }
`;

export const Year = styled.div`
  ${tokens.font.date};

  position: relative;
  font-family: ${tokens.font.family.bebas};

  &:before {
    position: absolute;
    top: 25px;
    left: -50px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-left: 10px solid #ffffff;
  }
`;

export const SocialIcon = styled(SocialIconComponent)`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;
