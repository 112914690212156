import React from 'react';

const Logo = () => (
  <svg
    data-testid='logo'
    width='399'
    height='100'
    viewBox='0 0 399 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M22.3795 74.6944L67.3208 49.9521L9.07104 92.1065C9.07104 92.1065 8.2923 93.9875 7.67641 94.2147C6.82869 94.5272 3.89657 93.3038 1.25464 90.0697C0.0579567 88.6048 0 80.1627 0 80.1627L0.0265295 18.2456V16.376C0.0265295 16.376 -0.11877 13.6339 0.280396 12.4207C0.631402 11.3503 3.80106 8.62979 3.94636 8.5221C4.64593 7.99668 4.67409 7.97751 5.28018 7.50349C5.72261 7.15634 5.68098 7.19918 6.82787 6.37311C13.3158 1.69454 16.9005 0.445048 16.7013 0.557637C16.0418 0.928039 15.11 1.34372 14.5153 2.40802C14.01 3.31322 13.6313 5.16767 13.4292 6.11897C13.3419 6.52976 13.3472 8.58614 13.3472 8.58614L22.3795 74.6944Z'
        fill='url(#paint0_linear)'
      />
      <path
        d='M15.8683 0.960277C13.5786 2.51531 13.3472 6.97482 13.3472 6.97482V8.95492L22.3794 74.6944L24.2736 25.2094L88.9211 56.7384V52.2222V47.8626C88.9211 47.8626 88.8652 42.9597 88.444 41.9493C87.495 39.6746 85 36.9207 82.7246 35.6312C81.3431 34.8492 77.2824 32.5232 77.2824 32.5232L21.9611 0.465455C21.9611 0.465455 18.5478 -0.858691 15.8683 0.960277Z'
        fill='url(#paint1_linear)'
      />
      <path
        d='M4.37775 92.9934L8.27717 95.4283L11.4746 97.4105C11.4746 97.4105 14.9699 99.7495 16.6699 99.9119C19.0592 100.14 20.5269 99.9021 21.5889 99.4819C23.4333 98.7534 29.2934 95.0163 29.2934 95.0163L79.6337 66.5631C79.6337 66.5631 83.2948 64.3635 84.8486 63.3331C85.9518 62.6004 87.6175 61.57 88.2517 60.5514C88.6823 59.8595 88.9909 58.6198 88.9909 57.4898C88.9909 56.2685 88.5913 54.9537 87.3134 53.9392C85.4233 52.4376 82.5316 50.9931 82.5316 50.9931L63.0185 41.636L24.2732 25.2098L67.3208 49.9521L8.29758 92.3342C8.29758 92.3342 7.48047 92.8698 6.43725 93.2104C5.37036 93.5596 4.37775 92.9934 4.37775 92.9934Z'
        fill='url(#paint2_linear)'
      />
      <path
        d='M149.006 42.3074H142.348L140.707 47.1047H136.464L144.268 25.284H147.395L155.199 47.1047H150.739L149.006 42.3074ZM143.431 38.9028H148.076L146.466 34.0741L145.816 30.6695H145.662L145.011 34.1056L143.431 38.9028Z'
        fill='white'
      />
      <path
        d='M170.776 47.1047H157.058V25.4391H161.301V43.3289H170.776V47.1047H170.776Z'
        fill='white'
      />
      <path
        d='M172.975 25.4391H185.825V29.2149H177.217V34.2598H185.052V38.0364H177.217V43.3289H185.981V47.1047H172.975V25.4391Z'
        fill='white'
      />
      <path
        d='M194.248 36.0857L188.208 25.4391H193.318L196.664 31.691L197.407 33.8881L198.118 31.691L201.617 25.4391H206.232L199.977 35.8695L206.542 47.1047H201.493L197.685 40.4195L196.849 38.129L196.043 40.4195L192.203 47.1047H187.589L194.248 36.0857Z'
        fill='white'
      />
      <path
        d='M224.997 41.286C224.997 40.6255 224.755 40.0899 224.27 39.6771C223.785 39.2643 223.176 38.8877 222.443 38.5471C221.71 38.2061 220.914 37.8561 220.058 37.4951C219.201 37.134 218.405 36.6894 217.675 36.164C216.939 35.6373 216.33 34.9822 215.846 34.1982C215.361 33.4141 215.119 32.4139 215.119 31.1966C215.119 30.1433 215.294 29.2308 215.645 28.4577C215.996 27.6839 216.497 27.0385 217.147 26.5229C217.799 26.0077 218.566 25.6251 219.455 25.3775C220.342 25.1302 221.333 25.0062 222.427 25.0062C223.685 25.0062 224.873 25.1139 225.99 25.3309C227.104 25.548 228.022 25.8625 228.744 26.2753L227.412 29.8341C226.958 29.5461 226.278 29.2822 225.368 29.0448C224.461 28.8082 223.48 28.6886 222.427 28.6886C221.436 28.6886 220.677 28.8853 220.151 29.2769C219.625 29.6693 219.361 30.1947 219.361 30.8552C219.361 31.474 219.603 31.9904 220.088 32.4033C220.573 32.8161 221.183 33.1975 221.915 33.5487C222.648 33.9 223.444 34.2602 224.3 34.6314C225.157 35.0038 225.953 35.4521 226.686 35.9784C227.419 36.5038 228.028 37.154 228.513 37.9283C228.998 38.7013 229.24 39.6669 229.24 40.8213C229.24 41.9778 229.049 42.9728 228.667 43.809C228.285 44.6441 227.748 45.3408 227.058 45.898C226.366 46.4549 225.529 46.8677 224.549 47.1365C223.569 47.4045 222.491 47.5383 221.313 47.5383C219.764 47.5383 218.402 47.3943 217.224 47.1051C216.049 46.8163 215.18 46.5279 214.624 46.2387L215.986 42.6171C216.214 42.7407 216.508 42.8757 216.869 43.0197C217.231 43.1637 217.639 43.2975 218.091 43.4215C218.546 43.5451 219.025 43.6487 219.532 43.7307C220.038 43.8139 220.56 43.8543 221.095 43.8543C222.354 43.8543 223.32 43.6434 223.992 43.2204C224.662 42.7974 224.997 42.152 224.997 41.286Z'
        fill='white'
      />
      <path
        d='M242.743 42.3074H236.085L234.444 47.1047H230.202L238.005 25.284H241.133L248.936 47.1047H244.478L242.743 42.3074ZM237.168 38.9028H241.815L240.203 34.0741L239.554 30.6695H239.399L238.747 34.1056L237.168 38.9028Z'
        fill='white'
      />
      <path
        d='M256.183 35.8075L254.48 32.4029H254.324L254.819 35.8075V47.1043H250.793V25.2836H253.891L262.097 36.8289L263.739 40.1401H263.893L263.398 36.8289V25.4391H267.423V47.2601H264.327L256.183 35.8075Z'
        fill='white'
      />
      <path
        d='M271.138 25.439C271.572 25.377 272.062 25.3252 272.61 25.284C273.156 25.2432 273.718 25.2118 274.297 25.1914C274.875 25.171 275.437 25.1551 275.985 25.1449C276.532 25.1347 277.023 25.1298 277.456 25.1298C279.376 25.1298 281.018 25.3974 282.38 25.9343C283.742 26.4707 284.846 27.2238 285.693 28.1934C286.539 29.1643 287.159 30.3187 287.552 31.66C287.944 33.0021 288.139 34.4772 288.139 36.0861C288.139 37.5522 287.955 38.9653 287.583 40.3265C287.212 41.689 286.592 42.8961 285.723 43.9481C284.857 45.0002 283.716 45.8471 282.303 46.4863C280.888 47.1251 279.138 47.4462 277.054 47.4462C276.723 47.4462 276.284 47.4351 275.738 47.4147C275.19 47.3943 274.624 47.3678 274.035 47.3376C273.446 47.3062 272.884 47.276 272.346 47.2442C271.81 47.2136 271.406 47.1773 271.138 47.1365V25.439ZM277.796 28.9052C277.342 28.9052 276.883 28.9154 276.418 28.9354C275.953 28.9571 275.608 28.9885 275.381 29.0289V43.5447C275.463 43.5659 275.602 43.581 275.799 43.5912C275.995 43.6022 276.206 43.6124 276.434 43.6226C276.661 43.6328 276.879 43.643 277.084 43.6532C277.291 43.6642 277.445 43.6683 277.549 43.6683C278.747 43.6683 279.743 43.4574 280.537 43.0344C281.332 42.6113 281.962 42.0492 282.427 41.3476C282.892 40.6463 283.217 39.8358 283.401 38.9179C283.588 37.9997 283.682 37.0565 283.682 36.0857C283.682 35.2408 283.598 34.3895 283.434 33.5324C283.268 32.6762 282.963 31.9072 282.521 31.2264C282.076 30.5459 281.471 29.9887 280.708 29.5555C279.944 29.1227 278.974 28.9052 277.796 28.9052Z'
        fill='white'
      />
      <path
        d='M291.174 25.6553C291.649 25.5729 292.16 25.4958 292.706 25.424C293.255 25.3522 293.801 25.2955 294.349 25.2539C294.895 25.2122 295.427 25.1821 295.944 25.1604C296.46 25.14 296.935 25.1302 297.367 25.1302C298.38 25.1302 299.375 25.2224 300.355 25.4081C301.335 25.5937 302.214 25.9143 302.988 26.3679C303.763 26.8224 304.383 27.4465 304.845 28.2407C305.31 29.035 305.543 30.0311 305.543 31.2272C305.543 33.0025 305.129 34.4462 304.303 35.5611C303.479 36.6747 302.373 37.449 300.99 37.8822L302.509 38.8421L307.462 47.1055H302.57L297.647 38.6246L295.416 38.2228V47.1051H291.173V25.6553H291.174ZM297.678 28.9053C297.243 28.9053 296.815 28.9154 296.393 28.9354C295.969 28.9571 295.644 28.9983 295.416 29.0603V35.3432H297.213C298.39 35.3432 299.328 35.0756 300.03 34.5388C300.733 34.0024 301.084 33.1147 301.084 31.877C301.084 30.949 300.793 30.2216 300.217 29.695C299.639 29.168 298.792 28.9053 297.678 28.9053Z'
        fill='white'
      />
      <path
        d='M308.114 36.2713C308.114 32.6607 308.908 29.8802 310.497 27.9307C312.087 25.9808 314.358 25.0058 317.31 25.0058C318.859 25.0058 320.211 25.2738 321.367 25.8103C322.522 26.3467 323.483 27.1099 324.248 28.1008C325.011 29.0909 325.58 30.2779 325.951 31.6596C326.323 33.0425 326.507 34.5804 326.507 36.2713C326.507 39.8827 325.707 42.6632 324.108 44.6131C322.508 46.5626 320.241 47.5375 317.31 47.5375C315.741 47.5375 314.378 47.2695 313.223 46.7331C312.066 46.1967 311.112 45.4326 310.359 44.4426C309.603 43.4525 309.041 42.2658 308.67 40.8829C308.299 39.5013 308.114 37.9634 308.114 36.2713ZM312.572 36.2713C312.572 37.3662 312.665 38.3709 312.852 39.2892C313.037 40.207 313.321 40.9963 313.703 41.6568C314.085 42.3172 314.576 42.8337 315.174 43.2049C315.773 43.5761 316.486 43.7617 317.31 43.7617C318.818 43.7617 319.983 43.1735 320.809 41.9974C321.635 40.8205 322.048 38.9122 322.048 36.2709C322.048 35.2188 321.96 34.239 321.786 33.3309C321.608 32.4228 321.337 31.6286 320.965 30.9482C320.594 30.2665 320.106 29.7358 319.51 29.3536C318.909 28.9721 318.178 28.7812 317.309 28.7812C315.803 28.7812 314.636 29.385 313.812 30.592C312.986 31.7995 312.572 33.6927 312.572 36.2713Z'
        fill='white'
      />
      <path
        d='M336.976 25.439C337.41 25.377 337.899 25.3252 338.447 25.284C338.993 25.2432 339.556 25.2118 340.134 25.1914C340.713 25.171 341.275 25.1551 341.823 25.1449C342.369 25.1347 342.86 25.1298 343.293 25.1298C345.213 25.1298 346.856 25.3974 348.218 25.9343C349.58 26.4707 350.684 27.2238 351.531 28.1934C352.377 29.1643 352.996 30.3187 353.39 31.66C353.782 33.0021 353.977 34.4772 353.977 36.0861C353.977 37.5522 353.792 38.9653 353.421 40.3265C353.049 41.689 352.43 42.8961 351.561 43.9481C350.694 45.0002 349.554 45.8471 348.141 46.4863C346.726 47.1251 344.976 47.4462 342.891 47.4462C342.56 47.4462 342.122 47.4351 341.576 47.4147C341.027 47.3943 340.461 47.3678 339.872 47.3376C339.284 47.3062 338.721 47.276 338.184 47.2442C337.648 47.2136 337.244 47.1773 336.976 47.1365V25.439ZM343.634 28.9052C343.18 28.9052 342.721 28.9154 342.256 28.9354C341.791 28.9571 341.446 28.9885 341.219 29.0289V43.5447C341.302 43.5659 341.44 43.581 341.636 43.5912C341.833 43.6022 342.045 43.6124 342.272 43.6226C342.5 43.6328 342.717 43.643 342.922 43.6532C343.129 43.6642 343.283 43.6683 343.387 43.6683C344.584 43.6683 345.581 43.4574 346.375 43.0344C347.17 42.6113 347.799 42.0492 348.265 41.3476C348.73 40.6463 349.054 39.8358 349.239 38.9179C349.426 37.9997 349.519 37.0565 349.519 36.0857C349.519 35.2408 349.436 34.3895 349.272 33.5324C349.105 32.6762 348.801 31.9072 348.359 31.2264C347.914 30.5459 347.309 29.9887 346.546 29.5555C345.782 29.1227 344.812 28.9052 343.634 28.9052Z'
        fill='white'
      />
      <path
        d='M357.444 25.4391H361.687V47.1047H357.444V25.4391Z'
        fill='white'
      />
      <path
        d='M376.52 42.3074H369.861L368.221 47.1047H363.978L371.781 25.284H374.91L382.713 47.1047H378.255L376.52 42.3074ZM370.945 38.9028H375.592L373.98 34.0741L373.331 30.6695H373.176L372.525 34.1056L370.945 38.9028Z'
        fill='white'
      />
      <path
        d='M393.923 41.286C393.923 40.6255 393.681 40.0899 393.196 39.6771C392.711 39.2643 392.102 38.8877 391.369 38.5471C390.636 38.2061 389.841 37.8561 388.984 37.4951C388.127 37.134 387.331 36.6894 386.6 36.164C385.865 35.6373 385.257 34.9822 384.772 34.1982C384.287 33.4141 384.045 32.4139 384.045 31.1966C384.045 30.1433 384.22 29.2308 384.571 28.4577C384.922 27.6839 385.423 27.0385 386.073 26.5229C386.725 26.0077 387.492 25.6251 388.381 25.3775C389.268 25.1302 390.259 25.0062 391.353 25.0062C392.611 25.0062 393.799 25.1139 394.916 25.3309C396.03 25.548 396.948 25.8625 397.67 26.2753L396.338 29.8341C395.884 29.5461 395.204 29.2822 394.294 29.0448C393.387 28.8082 392.406 28.6886 391.353 28.6886C390.362 28.6886 389.603 28.8853 389.077 29.2769C388.551 29.6693 388.287 30.1947 388.287 30.8552C388.287 31.474 388.529 31.9904 389.014 32.4033C389.499 32.8161 390.108 33.1975 390.841 33.5487C391.574 33.9 392.37 34.2602 393.227 34.6314C394.083 35.0038 394.879 35.4521 395.612 35.9784C396.344 36.5038 396.953 37.154 397.439 37.9283C397.924 38.7013 398.166 39.6669 398.166 40.8213C398.166 41.9778 397.975 42.9728 397.593 43.809C397.211 44.6441 396.674 45.3408 395.984 45.898C395.291 46.4549 394.455 46.8677 393.475 47.1365C392.494 47.4045 391.417 47.5383 390.239 47.5383C388.69 47.5383 387.328 47.3943 386.151 47.1051C384.975 46.8163 384.106 46.5279 383.55 46.2387L384.912 42.6171C385.14 42.7407 385.434 42.8757 385.795 43.0197C386.157 43.1637 386.564 43.2975 387.017 43.4215C387.472 43.5451 387.951 43.6487 388.458 43.7307C388.964 43.8139 389.486 43.8543 390.021 43.8543C391.28 43.8543 392.246 43.6434 392.918 43.2204C393.588 42.7974 393.923 42.152 393.923 41.286Z'
        fill='white'
      />
      <path
        d='M140.552 74.5181C140.189 74.8836 139.746 75.0639 139.225 75.0639C138.703 75.0639 138.26 74.8836 137.897 74.5181C137.533 74.1551 137.351 73.6962 137.351 73.1446V60.6305C137.351 60.0928 137.533 59.638 137.897 59.2668C138.26 58.8956 138.703 58.7112 139.225 58.7112C139.746 58.7112 140.189 58.8956 140.552 59.2668C140.917 59.638 141.098 60.0924 141.098 60.6305V61.8861H140.719V60.6305C140.719 60.2042 140.572 59.8407 140.28 59.5409C139.987 59.2407 139.636 59.0906 139.225 59.0906C138.813 59.0906 138.462 59.2407 138.169 59.5409C137.877 59.8407 137.731 60.2042 137.731 60.6305V73.1446C137.731 73.5709 137.877 73.934 138.169 74.2342C138.462 74.5345 138.813 74.6846 139.225 74.6846C139.636 74.6846 139.987 74.5345 140.28 74.2342C140.572 73.934 140.719 73.5709 140.719 73.1446V71.889H141.098V73.1446C141.098 73.6962 140.917 74.1551 140.552 74.5181Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M147.216 75.064V62.9047H147.596V63.688C147.769 63.4669 147.987 63.28 148.248 63.1299C148.509 62.9798 148.789 62.9047 149.09 62.9047C149.342 62.9047 149.584 62.9618 149.813 63.0708C150.042 63.1825 150.244 63.3331 150.417 63.5211C150.592 63.712 150.726 63.929 150.821 64.1726C150.915 64.4182 150.963 64.6756 150.963 64.9436H150.584C150.584 64.4851 150.437 64.0938 150.145 63.7712C149.852 63.4465 149.501 63.2841 149.09 63.2841C148.679 63.2841 148.327 63.4383 148.034 63.7467C147.742 64.0555 147.596 64.4145 147.596 64.8261V75.064H147.216Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M159.618 74.5181C159.254 74.8836 158.812 75.064 158.29 75.064C157.768 75.064 157.326 74.8836 156.962 74.5181C156.598 74.1551 156.416 73.6962 156.416 73.1446V64.8261C156.416 64.3047 156.598 63.854 156.962 63.4746C157.326 63.0952 157.768 62.9047 158.29 62.9047C158.812 62.9047 159.254 63.0956 159.618 63.4746C159.982 63.854 160.164 64.3043 160.164 64.8261V68.5693H156.796V73.1446C156.796 73.5709 156.942 73.934 157.235 74.2342C157.527 74.5345 157.879 74.6846 158.29 74.6846C158.701 74.6846 159.052 74.5345 159.346 74.2342C159.638 73.934 159.784 73.5709 159.784 73.1446V71.889H160.163V73.1446C160.163 73.6962 159.982 74.1551 159.618 74.5181ZM156.796 68.2144H159.784V64.8261C159.784 64.4141 159.638 64.0551 159.346 63.7467C159.052 63.4383 158.701 63.2841 158.29 63.2841C157.879 63.2841 157.527 63.4383 157.235 63.7467C156.942 64.0555 156.797 64.4145 156.797 64.8261V68.2144H156.796Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M169.648 75.088V74.3297C169.268 74.8347 168.77 75.088 168.153 75.088C167.632 75.088 167.189 74.9016 166.826 74.5304C166.461 74.1592 166.28 73.7047 166.28 73.1667V69.7319C166.28 69.2105 166.461 68.7598 166.826 68.3804C167.189 68.0006 167.632 67.8105 168.153 67.8105C168.47 67.8105 168.754 67.8815 169.007 68.0255C169.26 68.1675 169.473 68.3563 169.648 68.5937V64.8481C169.648 64.4381 169.501 64.0792 169.209 63.7708C168.916 63.4624 168.564 63.3078 168.154 63.3078C167.743 63.3078 167.391 63.4624 167.098 63.7708C166.806 64.0792 166.66 64.4381 166.66 64.8481V66.1037H166.28V64.8481C166.28 64.3268 166.462 63.876 166.826 63.4966C167.19 63.1173 167.632 62.9284 168.154 62.9284C168.675 62.9284 169.118 63.1173 169.481 63.4966C169.846 63.876 170.028 64.3264 170.028 64.8481V75.088H169.648ZM167.098 74.2583C167.391 74.5585 167.743 74.7086 168.154 74.7086C168.565 74.7086 168.916 74.5585 169.209 74.2583C169.501 73.9585 169.648 73.595 169.648 73.1667V69.7319C169.648 69.3199 169.501 68.9609 169.209 68.6525C168.916 68.3437 168.564 68.1895 168.154 68.1895C167.743 68.1895 167.391 68.3437 167.098 68.6525C166.806 68.9609 166.66 69.3199 166.66 69.7319V73.1667C166.66 73.595 166.806 73.9585 167.098 74.2583Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M178.493 75.0639C177.987 75.0639 177.548 74.8836 177.177 74.5181C176.806 74.1551 176.62 73.6962 176.62 73.1446V63.2841H175.576V62.9047H176.62V59.9423H176.999V62.9047H178.588V63.2841H176.999V73.1446C176.999 73.5709 177.157 73.934 177.473 74.2342C177.789 74.5345 178.168 74.6846 178.611 74.6846V74.9465L178.493 75.0639Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M184.065 61.6736V59.7543H184.445V61.6736H184.065ZM184.065 75.0639V62.9047H184.445V75.0639H184.065Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M191.749 75.064L190.017 62.9047H190.397L191.914 73.6174C192.183 71.8176 192.436 70.0321 192.673 68.2609C192.911 66.4917 193.164 64.7066 193.433 62.9047H193.788L192.081 75.064H191.749Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M202.586 74.5181C202.222 74.8836 201.78 75.064 201.258 75.064C200.737 75.064 200.294 74.8836 199.931 74.5181C199.567 74.1551 199.385 73.6962 199.385 73.1446V64.8261C199.385 64.3047 199.567 63.854 199.931 63.4746C200.294 63.0952 200.737 62.9047 201.258 62.9047C201.78 62.9047 202.222 63.0956 202.586 63.4746C202.949 63.854 203.132 64.3043 203.132 64.8261V68.5693H199.764V73.1446C199.764 73.5709 199.91 73.934 200.203 74.2342C200.495 74.5345 200.846 74.6846 201.258 74.6846C201.668 74.6846 202.022 74.5345 202.314 74.2342C202.607 73.934 202.752 73.5709 202.752 73.1446V71.889H203.132V73.1446C203.132 73.6962 202.949 74.1551 202.586 74.5181ZM199.764 68.2144H202.752V64.8261C202.752 64.4141 202.607 64.0551 202.314 63.7467C202.022 63.4383 201.668 63.2841 201.258 63.2841C200.846 63.2841 200.495 63.4383 200.203 63.7467C199.91 64.0555 199.764 64.4145 199.764 64.8261V68.2144Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M217.835 76.0116V57.7615H218.212V76.0116H217.835Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M232.914 75.0639V58.7112H236.47V59.0906H233.293V66.5325H235.997V66.8874H233.293V74.6846H236.47V75.0639H232.914Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M244.154 68.9976C244.424 69.9611 244.712 70.972 245.021 72.031C245.327 73.0904 245.615 74.1004 245.885 75.0644H245.506L243.965 69.6837C243.711 70.5849 243.457 71.4856 243.206 72.3863C242.952 73.2874 242.708 74.18 242.471 75.0644H242.067L243.799 68.998C243.528 68.0345 243.24 67.0179 242.934 65.9508C242.625 64.8856 242.337 63.8687 242.067 62.9051H242.471L243.965 68.3102C244.217 67.4254 244.475 66.5247 244.734 65.6077C244.997 64.6907 245.252 63.7916 245.506 62.9051H245.886L244.154 68.9976Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M254.659 74.5181C254.295 74.8836 253.853 75.064 253.331 75.064C252.809 75.064 252.367 74.8836 252.003 74.5181C251.64 74.1551 251.457 73.6962 251.457 73.1446V64.8261C251.457 64.3047 251.64 63.854 252.003 63.4746C252.367 63.0952 252.809 62.9047 253.331 62.9047C253.853 62.9047 254.295 63.0956 254.659 63.4746C255.022 63.8536 255.205 64.3043 255.205 64.8261V68.5693H251.837V73.1446C251.837 73.5709 251.983 73.934 252.275 74.2342C252.568 74.5345 252.919 74.6846 253.331 74.6846C253.741 74.6846 254.094 74.5345 254.387 74.2342C254.679 73.934 254.825 73.5709 254.825 73.1446V71.889H255.205V73.1446C255.205 73.6962 255.022 74.1551 254.659 74.5181ZM251.837 68.2144H254.825V64.8261C254.825 64.4141 254.679 64.0551 254.387 63.7467C254.094 63.4383 253.741 63.2841 253.331 63.2841C252.919 63.2841 252.568 63.4383 252.275 63.7467C251.983 64.0555 251.837 64.4145 251.837 64.8261V68.2144Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M264.524 74.5181C264.161 74.8836 263.718 75.064 263.197 75.064C262.675 75.064 262.233 74.8836 261.869 74.5181C261.506 74.1551 261.323 73.6962 261.323 73.1446V64.8261C261.323 64.3047 261.506 63.854 261.869 63.4746C262.233 63.0952 262.675 62.9047 263.197 62.9047C263.718 62.9047 264.161 63.0956 264.524 63.4746C264.888 63.8536 265.07 64.3043 265.07 64.8261V66.0817H264.691V64.8261C264.691 64.4141 264.545 64.0551 264.252 63.7467C263.96 63.4383 263.607 63.2841 263.197 63.2841C262.785 63.2841 262.433 63.4383 262.141 63.7467C261.849 64.0555 261.703 64.4145 261.703 64.8261V73.1446C261.703 73.5709 261.849 73.934 262.141 74.2342C262.433 74.5345 262.785 74.6846 263.197 74.6846C263.607 74.6846 263.96 74.5345 264.252 74.2342C264.545 73.934 264.691 73.5709 264.691 73.1446V71.889H265.07V73.1446C265.07 73.6962 264.887 74.1551 264.524 74.5181Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M274.388 74.5181C274.025 74.8836 273.582 75.064 273.06 75.064C272.539 75.064 272.096 74.8836 271.733 74.5181C271.369 74.1551 271.187 73.6962 271.187 73.1446V62.9047H271.566V73.1446C271.566 73.5709 271.712 73.934 272.005 74.2342C272.297 74.5345 272.648 74.6846 273.06 74.6846C273.47 74.6846 273.824 74.5345 274.116 74.2342C274.408 73.934 274.555 73.5709 274.555 73.1446V62.9047H274.934V73.1446C274.934 73.6962 274.751 74.1551 274.388 74.5181Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M283.401 75.0639C282.894 75.0639 282.455 74.8836 282.084 74.5181C281.712 74.1551 281.527 73.6962 281.527 73.1446V63.2841H280.484V62.9047H281.527V59.9423H281.907V62.9047H283.495V63.2841H281.907V73.1446C281.907 73.5709 282.066 73.934 282.38 74.2342C282.697 74.5345 283.076 74.6846 283.519 74.6846V74.9465L283.401 75.0639Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M288.973 61.6736V59.7543H289.351V61.6736H288.973ZM288.973 75.0639V62.9047H289.351V75.0639H288.973Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M296.654 75.064L294.923 62.9047H295.303L296.821 73.6174C297.089 71.8176 297.343 70.0321 297.581 68.2609C297.816 66.4917 298.069 64.7066 298.337 62.9047H298.695L296.988 75.064H296.654Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M307.493 74.5181C307.129 74.8836 306.687 75.064 306.165 75.064C305.643 75.064 305.201 74.8836 304.837 74.5181C304.474 74.1551 304.291 73.6962 304.291 73.1446V64.8261C304.291 64.3047 304.474 63.854 304.837 63.4746C305.201 63.0952 305.643 62.9047 306.165 62.9047C306.687 62.9047 307.129 63.0956 307.493 63.4746C307.856 63.8536 308.039 64.3043 308.039 64.8261V68.5693H304.671V73.1446C304.671 73.5709 304.817 73.934 305.11 74.2342C305.402 74.5345 305.753 74.6846 306.165 74.6846C306.575 74.6846 306.928 74.5345 307.221 74.2342C307.513 73.934 307.659 73.5709 307.659 73.1446V71.889H308.039V73.1446C308.039 73.6962 307.856 74.1551 307.493 74.5181ZM304.671 68.2144H307.659V64.8261C307.659 64.4141 307.513 64.0551 307.221 63.7467C306.928 63.4383 306.575 63.2841 306.165 63.2841C305.753 63.2841 305.402 63.4383 305.11 63.7467C304.817 64.0555 304.671 64.4145 304.671 64.8261V68.2144Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M322.764 75.0639V58.7112H324.472C324.725 58.7112 324.967 58.7618 325.196 58.8654C325.423 58.967 325.623 59.1048 325.789 59.2794C325.953 59.454 326.087 59.6547 326.191 59.884C326.294 60.1132 326.345 60.3625 326.345 60.6305V64.9905C326.345 65.2605 326.294 65.5081 326.191 65.7374C326.087 65.9667 325.953 66.1698 325.789 66.3415C325.623 66.5161 325.423 66.6544 325.196 66.7576C324.966 66.8592 324.725 66.9122 324.472 66.9122H323.144V75.0639H322.764ZM324.472 66.5325C324.884 66.5325 325.235 66.3864 325.527 66.0943C325.82 65.8023 325.965 65.4331 325.965 64.9905V60.6305C325.965 60.2042 325.82 59.8407 325.527 59.5409C325.235 59.2407 324.884 59.0906 324.472 59.0906H323.144V66.5325H324.472Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M332.461 75.064V62.9047H332.841V63.688C333.016 63.4669 333.233 63.28 333.493 63.1299C333.755 62.9798 334.035 62.9047 334.335 62.9047C334.589 62.9047 334.828 62.9618 335.058 63.0708C335.287 63.1825 335.489 63.3331 335.663 63.5211C335.838 63.712 335.972 63.929 336.067 64.1726C336.162 64.4182 336.209 64.6756 336.209 64.9436H335.829C335.829 64.4851 335.683 64.0938 335.391 63.7712C335.098 63.4465 334.745 63.2841 334.335 63.2841C333.923 63.2841 333.572 63.4383 333.279 63.7467C332.987 64.0555 332.841 64.4145 332.841 64.8261V75.064H332.461Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M344.862 74.5181C344.499 74.8836 344.056 75.064 343.535 75.064C343.013 75.064 342.571 74.8836 342.207 74.5181C341.844 74.1551 341.661 73.6962 341.661 73.1446V64.8261C341.661 64.3047 341.844 63.854 342.207 63.4746C342.571 63.0952 343.013 62.9047 343.535 62.9047C344.056 62.9047 344.499 63.0956 344.862 63.4746C345.226 63.854 345.409 64.3043 345.409 64.8261V73.1446C345.409 73.6962 345.225 74.1551 344.862 74.5181ZM342.479 74.2342C342.772 74.5345 343.123 74.6846 343.535 74.6846C343.945 74.6846 344.298 74.5345 344.59 74.2342C344.883 73.934 345.029 73.5709 345.029 73.1446V64.8261C345.029 64.4141 344.883 64.0551 344.59 63.7467C344.298 63.4383 343.945 63.2841 343.535 63.2841C343.123 63.2841 342.772 63.4383 342.479 63.7467C342.187 64.0555 342.041 64.4145 342.041 64.8261V73.1446C342.041 73.5709 342.187 73.9336 342.479 74.2342Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M354.895 75.0639V74.3052C354.515 74.8127 354.018 75.0639 353.401 75.0639C352.879 75.0639 352.437 74.8836 352.073 74.5181C351.71 74.1551 351.527 73.6962 351.527 73.1446V64.8261C351.527 64.3047 351.71 63.854 352.073 63.4746C352.437 63.0952 352.879 62.9047 353.401 62.9047C353.701 62.9047 353.981 62.9798 354.243 63.1299C354.503 63.28 354.72 63.4669 354.895 63.6879V58.7112H355.274V75.0639H354.895ZM352.345 74.2342C352.637 74.5345 352.988 74.6846 353.401 74.6846C353.811 74.6846 354.164 74.5345 354.456 74.2342C354.749 73.934 354.895 73.5709 354.895 73.1446V64.8261C354.895 64.4141 354.749 64.0551 354.456 63.7467C354.164 63.4383 353.811 63.2841 353.401 63.2841C352.988 63.2841 352.637 63.4383 352.345 63.7467C352.053 64.0555 351.907 64.4145 351.907 64.8261V73.1446C351.907 73.5709 352.053 73.9336 352.345 74.2342Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M364.592 74.5181C364.228 74.8836 363.786 75.064 363.264 75.064C362.743 75.064 362.3 74.8836 361.936 74.5181C361.573 74.1551 361.39 73.6962 361.39 73.1446V62.9047H361.77V73.1446C361.77 73.5709 361.916 73.934 362.209 74.2342C362.501 74.5345 362.852 74.6846 363.264 74.6846C363.674 74.6846 364.027 74.5345 364.32 74.2342C364.612 73.934 364.758 73.5709 364.758 73.1446V62.9047H365.138V73.1446C365.138 73.6962 364.955 74.1551 364.592 74.5181Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M374.457 74.5181C374.093 74.8836 373.651 75.064 373.129 75.064C372.608 75.064 372.165 74.8836 371.802 74.5181C371.438 74.1551 371.256 73.6962 371.256 73.1446V64.8261C371.256 64.3047 371.438 63.854 371.802 63.4746C372.165 63.0952 372.608 62.9047 373.129 62.9047C373.651 62.9047 374.093 63.0956 374.457 63.4746C374.821 63.8536 375.003 64.3043 375.003 64.8261V66.0817H374.624V64.8261C374.624 64.4141 374.478 64.0551 374.185 63.7467C373.893 63.4383 373.54 63.2841 373.129 63.2841C372.717 63.2841 372.366 63.4383 372.074 63.7467C371.781 64.0555 371.635 64.4145 371.635 64.8261V73.1446C371.635 73.5709 371.781 73.934 372.074 74.2342C372.366 74.5345 372.717 74.6846 373.129 74.6846C373.54 74.6846 373.893 74.5345 374.185 74.2342C374.478 73.934 374.624 73.5709 374.624 73.1446V71.889H375.003V73.1446C375.003 73.6962 374.821 74.1551 374.457 74.5181Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M384.132 74.5181C383.769 74.8836 383.326 75.064 382.805 75.064C382.283 75.064 381.841 74.8836 381.477 74.5181C381.114 74.1551 380.931 73.6962 380.931 73.1446V64.8261C380.931 64.3047 381.114 63.854 381.477 63.4746C381.841 63.0952 382.283 62.9047 382.805 62.9047C383.326 62.9047 383.769 63.0956 384.132 63.4746C384.496 63.854 384.678 64.3043 384.678 64.8261V68.5693H381.311V73.1446C381.311 73.5709 381.456 73.934 381.749 74.2342C382.041 74.5345 382.392 74.6846 382.805 74.6846C383.215 74.6846 383.568 74.5345 383.86 74.2342C384.153 73.934 384.299 73.5709 384.299 73.1446V71.889H384.678V73.1446C384.678 73.6962 384.496 74.1551 384.132 74.5181ZM381.31 68.2144H384.298V64.8261C384.298 64.4141 384.152 64.0551 383.86 63.7467C383.567 63.4383 383.214 63.2841 382.804 63.2841C382.392 63.2841 382.041 63.4383 381.749 63.7467C381.456 64.0555 381.31 64.4145 381.31 64.8261V68.2144Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
      <path
        d='M390.794 75.064V62.9047H391.174V63.688C391.349 63.4669 391.566 63.28 391.826 63.1299C392.088 62.9798 392.368 62.9047 392.668 62.9047C392.922 62.9047 393.162 62.9618 393.391 63.0708C393.621 63.1825 393.822 63.3331 393.996 63.5211C394.171 63.712 394.304 63.929 394.4 64.1726C394.495 64.4182 394.542 64.6756 394.542 64.9436H394.162C394.162 64.4851 394.016 64.0938 393.724 63.7712C393.431 63.4465 393.078 63.2841 392.668 63.2841C392.256 63.2841 391.905 63.4383 391.613 63.7467C391.321 64.0555 391.174 64.4145 391.174 64.8261V75.064H390.794Z'
        fill='#F1F1F2'
        stroke='#F1F1F2'
        strokeWidth='0.25'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear'
        x1='10.7935'
        y1='-17.2953'
        x2='33.5956'
        y2='67.8478'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0265' stopColor='#434345' />
        <stop offset='0.0965' stopColor='#434345' />
        <stop offset='0.2031' stopColor='#505052' />
        <stop offset='0.3997' stopColor='#727273' />
        <stop offset='0.6625' stopColor='#A8A8A9' />
        <stop offset='0.8204' stopColor='#CCCCCC' />
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='13.4905'
        y1='39.0629'
        x2='83.3905'
        y2='21.6257'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.0446' stopColor='#DFDFDF' />
        <stop offset='0.2986' stopColor='#DDDDDD' />
        <stop offset='0.4442' stopColor='#D4D4D4' />
        <stop offset='0.5623' stopColor='#C6C6C6' />
        <stop offset='0.6659' stopColor='#B3B3B3' />
        <stop offset='0.7597' stopColor='#999999' />
        <stop offset='0.8466' stopColor='#797979' />
        <stop offset='0.9265' stopColor='#545454' />
        <stop offset='1' stopColor='#2B2B2B' />
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='5.28036'
        y1='93.8956'
        x2='66.2762'
        y2='32.8678'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#6D6E70' />
        <stop offset='0.1407' stopColor='#7C7D7F' />
        <stop offset='0.4118' stopColor='#A4A4A5' />
        <stop offset='0.6997' stopColor='#D4D4D4' />
      </linearGradient>
      <clipPath id='clip0'>
        <rect width='398.165' height='100' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
