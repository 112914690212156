import React from 'react';
import { useTranslation } from 'react-i18next';

// icons
import { SocialIcon } from 'react-social-icons';

// styles
import { Wrapper, Item } from './styles';

const Contact = () => {
  // translation features
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Item>
        <SocialIcon
          network='whatsapp'
          bgColor='transparent'
          fgColor='#ffffff'
          url='https://api.whatsapp.com/send?phone=5541991678864&text=Hey%20Alex'
        />
        <a href='https://api.whatsapp.com/send?phone=5541991678864&text=Hey%20Alex'>
          {t(`contact.whatsapp`)}
        </a>
      </Item>
      <Item>
        <SocialIcon
          network='vimeo'
          bgColor='transparent'
          fgColor='#ffffff'
          url='https://vimeo.com/alexsd'
        />
        <a
          href='https://vimeo.com/alexsd'
          target='_blank'
          rel='nofollow noreferrer'
        >
          {t(`contact.vimeo`)}
        </a>
      </Item>
      <Item>
        <SocialIcon
          network='email'
          bgColor='transparent'
          fgColor='#ffffff'
          url='mailto:alex@alexsd.com.br'
        />
        <a href='mailto:alex@alexsd.com.br'>{t(`contact.email`)}</a>
      </Item>
    </Wrapper>
  );
};

Contact.propTypes = {};

export default Contact;
