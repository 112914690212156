import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
import { TitleWrapper, LogoWrapper, Text } from './styles';

// logo
import Logo from '../Logo/Logo';

const Title = () => {
  const { t } = useTranslation();

  return (
    <TitleWrapper data-testid='section-title'>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Text>
        <h1 className='title'>{t('intro.title')}</h1>
      </Text>
    </TitleWrapper>
  );
};

Title.propTypes = {};

export default Title;
