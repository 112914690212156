import { css } from 'styled-components';

const font = {
  'title': css`
    font-size: 72px;
    line-height: 86px;
    font-weight: 400;
    text-transform: uppercase;
  `,
  'title-sm': css`
    font-size: 60px;
    line-height: 72px;
    font-weight: 400;
    text-transform: uppercase;
  `,
  'date': css`
    display: inline-block;
    vertical-align: middle;
    font-size: 72px;
    line-height: 72px;
    font-weight: 400;
  `,
  'subtitle': css`
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 16px 8px;
  `,
  'eventTitle': css`
    font-weight: 300;
    font-size: 48px;
    line-height: 59px;
  `,
  'eventTitle-sm': css`
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
  `,
  'family': {
    bebas: "'Bebas Neue', Impact, sans-serif;",
    montserrat: "'Montserrat', sans-serif;",
  },
};

const breakpoints = {
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1400px)',
};

export default {
  font,
  breakpoints,
};
