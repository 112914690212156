import styled from 'styled-components';
import tokens from '../../../../styles/tokens';

export const TitleWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background-color: #111111;

  @media ${tokens.breakpoints.md} {
    text-align: center;
    padding: 64px;
  }

  @media ${tokens.breakpoints.lg} {
    text-align: left;
    flex-direction: row;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 32px;

  @media ${tokens.breakpoints.lg} {
    flex: 0 0 50%;
    padding: 50px;
  }

  svg {
    max-width: 300px;
  }
`;

export const Text = styled.div`
  @media ${tokens.breakpoints.lg} {
    display: flex;
    align-items: center;
  }

  .title {
    ${tokens.font['title-sm']};

    font-family: ${tokens.font.family.bebas};

    @media ${tokens.breakpoints.lg} {
      ${tokens.font.title};
      max-width: 386px;
      margin: 0 auto;
    }
  }
`;
