import styled from 'styled-components';
import tokens from '../../../../styles/tokens';

export const IntroWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background-color: #111111;

  @media ${tokens.breakpoints.md} {
    padding: 64px;
  }

  @media ${tokens.breakpoints.lg} {
    flex-direction: row;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  @media ${tokens.breakpoints.lg} {
    flex: 0 0 50%;
    padding: 50px;
    margin-bottom: 32px;
  }

  svg {
    max-width: 200px;

    @media ${tokens.breakpoints.lg} {
      max-width: 300px;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: justify;
  padding: 0 24px 0 0;
  font-size: 14px;
`;
