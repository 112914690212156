import React from 'react';

// components
import PageWrapper from './components/PageWrapper';

// modules
import Main from './modules/Main';
import Contact from './modules/Contact';

// global styles
import GlobalStyle from './styles/global';

function App() {
  return (
    <PageWrapper>
      <GlobalStyle />
      <Main />
      <Contact />
    </PageWrapper>
  );
}

export default App;
