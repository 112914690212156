import React from 'react';
import PropTypes from 'prop-types';

// styles
import PageWrapperComponent from './styles';

const PageWrapper = ({ children }) => (
  <PageWrapperComponent>{children}</PageWrapperComponent>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
