import React, { useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import ModalVideo from 'react-modal-video';

// components
import Title from '../components/Title';
import Intro from '../components/Intro';
import Event from '../components/Event';

// data
const data = require('./data.json');

const Main = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSettings, setVideoSettings] = useState({
    channel: 'vimeo',
    id: null,
  });

  return (
    <>
      <ModalVideo
        channel={videoSettings.channel}
        allowFullScreen
        videoId={videoSettings.id}
        isModalOpen={isModalOpen}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        youtube={{
          width: '100%',
        }}
        vimeo={{
          width: '100%',
        }}
      />
      <ReactPageScroller renderAllPagesOnFirstRender={false}>
        <Title />
        <Intro />
        {data.portfolio.map((item, index) => (
          <Event
            isModalOpen={isModalOpen}
            index={index}
            key={item.key}
            eventKey={item.key}
            year={item.year}
            image={item.image}
            video={item.video}
            setModalOpen={setModalOpen}
            setVideoSettings={setVideoSettings}
          />
        ))}
      </ReactPageScroller>
    </>
  );
};

export default Main;
