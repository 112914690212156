import styled from 'styled-components';
import tokens from '../../styles/tokens';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10000;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  transform: translate(150px, -50%);
  background-color: #111;
  border-radius: 4px;

  &:hover {
    @media ${tokens.breakpoints.xl} {
      transform: translate(0, -50%);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s ease;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
