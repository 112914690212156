import { createGlobalStyle } from 'styled-components';
import palette from './palette';
import tokens from './tokens';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: ${tokens.font.family.montserrat};
    margin: 0;
    padding: 0;
    background: ${palette['color-black']};
    color: ${palette['color-white']}
  }

  .modal-video,
  .modal-video:focus,
  .modal-video-close-btn:focus {
    outline: 0;
  }

  .modal-video {
    background-color: rgba(0,0,0,.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

export default GlobalStyle;
